import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import section_8 from "../assets/images/section-8.png";
import header from "../assets/images/header.png";
import creator_image from "../assets/images/creator_image.png";
import icon_twitter from "../assets/images/icon-twitter.png";
import icon_instagram from "../assets/images/icon-instagram.png";
import icon_discord from "../assets/images/icon-discord.png";

import CopyrightIcon from "@mui/icons-material/Copyright";

import "./Footers.scss";
import useCalculateHeightRatio from "../customHooks/useCalculateHeightRatio";
import { useHistory } from "react-router-dom";

const Footers = ({ JSONData }: any) => {
  const history = useHistory();

  const routeChange = () => {
    let path = `https://discord.com/invite/um7nXgHsXX`;

    history.push(path);
  };

  const changeRoute = () => {
    let path = `https://discord.com/invite/um7nXgHsXX`;
    window.location.href = path;
  };
  const { image_file_size, content } = JSONData;

  const { heightRatio } = useCalculateHeightRatio({
    sectionImageHeight: image_file_size,
  });

  return (
    <Box
      className="footers-container"
      style={{ backgroundImage: `url(${section_8})`, ...heightRatio }}
    >
      <img className="header" src={header} alt="header" />
      <Button onClick={changeRoute} variant="contained">
        Join the Community
      </Button>
      <Stack direction="row" className="creator-panel">
        <img src={creator_image} alt="creator-thumbnail" />
        <Stack>
          <h1>{content.creator_info.header}</h1>
          <p>{content.creator_info.description}</p>
        </Stack>
      </Stack>
      <Stack className="followers-panel">
        <h1>Follow Us On</h1>
        <Stack direction="row">
          <a target="_blank" href="https://twitter.com/EndangeredNft">
            <img src={icon_twitter} alt="twitter-icon" />
          </a>
          <a
            target="_blank"
            href="https://instagram.com/endangered_animals_nft"
          >
            <img src={icon_instagram} alt="instagram-icon" />
          </a>
          <a target="_blank" href="https://discord.com/invite/um7nXgHsXX">
            <img src={icon_discord} alt="discord-icon" />
          </a>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footers;
