import FlyingFrogs1 from "../assets/images/FlyingFrog1.png";
import FlyingFrogs2 from "../assets/images/FlyingFrog2.png";
import FlyingFrogs3 from "../assets/images/FlyingFrog3.png";
import FlyingFrogs4 from "../assets/images/FlyingFrog4.png";
import FlyingFrogs5 from "../assets/images/FlyingFrog5.png";
import FlyingFrogs6 from "../assets/images/FlyingFrog6.png";
import FlyingFrogs7 from "../assets/images/FlyingFrog7.png";
import FlyingFrogs8 from "../assets/images/FlyingFrog8.png";
import FlyingFrogs9 from "../assets/images/FlyingFrog9.png";
import FlyingFrogs10 from "../assets/images/FlyingFrog10.png";
import FlyingFrogs11 from "../assets/images/FlyingFrog11.png";
import FlyingFrogs12 from "../assets/images/FlyingFrog12.png";
import FlyingFrogs13 from "../assets/images/FlyingFrog13.png";
import FlyingFrogs14 from "../assets/images/FlyingFrog14.png";
import FlyingFrogs15 from "../assets/images/FlyingFrog15.png";
import FlyingFrogs16 from "../assets/images/FlyingFrog16.png";
import FlyingFrogs17 from "../assets/images/FlyingFrog17.png";
import FlyingFrogs18 from "../assets/images/FlyingFrog18.png";
import FlyingFrogs19 from "../assets/images/FlyingFrog19.png";
import FlyingFrogs20 from "../assets/images/FlyingFrog20.png";
import FlyingFrogs21 from "../assets/images/FlyingFrog21.png";
import FlyingFrogs22 from "../assets/images/FlyingFrog22.png";
import FlyingFrogs23 from "../assets/images/FlyingFrog23.png";
import FlyingFrogs24 from "../assets/images/FlyingFrog24.png";
import FlyingFrogs25 from "../assets/images/FlyingFrog25.png";
import FlyingFrogs26 from "../assets/images/FlyingFrog26.png";
import FlyingFrogs27 from "../assets/images/FlyingFrog27.png";
import FlyingFrogs28 from "../assets/images/FlyingFrog28.png";
import FlyingFrogs29 from "../assets/images/FlyingFrog29.png";
import FlyingFrogs30 from "../assets/images/FlyingFrog30.png";
import FlyingFrogs31 from "../assets/images/FlyingFrog31.png";
import FlyingFrogs32 from "../assets/images/FlyingFrog32.png";
import FlyingFrogs33 from "../assets/images/FlyingFrog33.png";
import FlyingFrogs34 from "../assets/images/FlyingFrog34.png";

export const thumbnails = [
  {
    label: "Flying Frogs",
    image: FlyingFrogs1,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs2,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs3,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs4,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs5,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs6,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs7,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs8,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs9,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs10,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs11,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs12,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs13,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs14,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs15,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs16,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs17,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs18,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs19,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs20,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs21,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs22,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs23,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs24,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs25,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs26,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs27,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs28,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs29,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs30,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs31,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs32,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs33,
  },
  {
    label: "Flying Frogs",
    image: FlyingFrogs34,
  },
];
