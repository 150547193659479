import React from "react";

import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import useImage from "react-use-image";

import "./App.scss";

import Headers from "./components/Headers";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Section7 from "./components/Section7";
import Footers from "./components/Footers";

import headerJSONData from "./json_data/header_json_data.json";
import sectionsJSONData from "./json_data/sections_json_data.json";
import footerJSONData from "./json_data/footer_json_data.json";

import { FC, useMemo } from "react";
import {
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import {
  getLedgerWallet,
  getMathWallet,
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolongWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";
import {
  WalletDialogProvider,
  WalletDisconnectButton,
} from "@solana/wallet-adapter-material-ui";
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

const theme = createTheme();
export const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);
export const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);
export const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);
export const network = process.env
  .REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
export const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
export const connection = new anchor.web3.Connection(rpcHost);

export const startDateSeed = parseInt(
  process.env.REACT_APP_CANDY_START_DATE!,
  10
);
export const txTimeout = 30000;
const App = () => {
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getTorusWallet({
        options: {
          clientId:
            "BOM5Cl7PXgE9Ylq1Z1tqzhpydY0RVr8k90QQ85N7AKI5QGSrr9iDC-3rvmy0K_hF0JfpLMiXoDhta68JwcxS1LQ",
        },
      }),
      getLedgerWallet(),
      getSolongWallet(),
      getMathWallet(),
      getSolletWallet(),
    ],
    []
  );
  const {
    section_1,
    section_2,
    section_3,
    section_4,
    section_5,
    section_6,
    section_7,
  } = sectionsJSONData;

  return (
    <ConnectionProvider endpoint={clusterApiUrl("devnet")}>
      <WalletProvider wallets={wallets}>
        <WalletDialogProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <Container maxWidth={false}>
              <Headers JSONData={headerJSONData} />
              <Section1 JSONData={section_1} />
              <Section2 JSONData={section_2} />
              <Section3 JSONData={section_3} />
              <Section4 JSONData={section_4} />
              <Section5 JSONData={section_5} />
              <Section6 JSONData={section_6} />
              <Section7 JSONData={section_7} />
              <Footers JSONData={footerJSONData} />
            </Container>
          </ThemeProvider>
        </WalletDialogProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
