import React from "react";

import Box from "@mui/material/Box";

import useCalculateHeightRatio from "../customHooks/useCalculateHeightRatio";
import section_5 from "../assets/images/section-5.png";
import rarity from "../assets/images/rarity.png";

import "./Section5.scss";
const Section5 = ({ JSONData }: any) => {
  const { image_file_size, content, variants } = JSONData;
  const variantsKeys = Object.keys(variants);

  const { heightRatio } = useCalculateHeightRatio({
    sectionImageHeight: image_file_size,
  });

  return (
    <Box
      id="Rarity"
      className="section-5-container"
      style={{ backgroundImage: `url(${section_5})`, ...heightRatio }}
    >
      <Box className="left-panel">
        <h1>{content.main_header}</h1>
        <h1 className="sub-header description">{content.description}</h1>
      </Box>
      <Box
        className="right-panel"
        style={{ backgroundImage: `url(${rarity})` }}
      >
        <Box>
          {variantsKeys &&
            variantsKeys.map((item, index) => {
              return (
                <span
                  key={index}
                  className={`${index % 2 === 0 ? "" : "even"}`}
                >
                  <p>{variants[item].count}</p>
                  <p>{variants[item].label}</p>
                </span>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default Section5;
