import roadmap_1 from "../assets/images/roadmap_1.png";
import roadmap_2 from "../assets/images/roadmap_2.png";
import roadmap_3 from "../assets/images/roadmap_3.png";
import roadmap_4 from "../assets/images/roadmap_4.png";
import roadmap_5 from "../assets/images/roadmap_5.png";
import roadmap_6 from "../assets/images/roadmap_6.png";

export const roadmapdata = [
  roadmap_1,
  roadmap_2,
  roadmap_3,
  roadmap_4,
  roadmap_5,
  roadmap_6,
];

export const roadmap_list = [
  {
    image: roadmap_1,
    title: "Art and Website",
    description: "Finalize the Endangered Animals NFT art and website",
  },
  {
    image: roadmap_2,
    title: "Pre-Launch Giveaways and Contests",
    description:
      "Begin community Endangered Animals NFT giveaways and contest for Twitter followers and Discord members, Special perks will be given to early followers!",
  },
  {
    image: roadmap_3,
    title: "Launch and Listings",
    description:
      "Get Endangered Animals NFT listed on all major Solana NFT marketplaces after the launch.",
  },
  {
    image: roadmap_4,
    title: "Pre-Launch Giveaways and Contests",
    description:
      "FREE merchandise for 50 holders who are holding 3 or more Endangered Animals NFT!\nGiveaway an additional 500 Endangered Animals NFT to holders of a minimum of 1 Endangered Animals NFT",
  },
  {
    image: roadmap_5,
    title: "Charity",
    description:
      "Giveaway donation to charities that are helping Endangered Animals. Discord will help choose.",
  },
  {
    image: roadmap_6,
    title: "Website Updates",
    description:
      "Create a merchandise store and reveal the next Endangered Animals NFT in the series.",
  },
];
