import frog_R1_C1 from "../assets/images/frog_R1_C1.png";
import frog_R1_C2 from "../assets/images/frog_R1_C2.png";
import frog_R1_C3 from "../assets/images/frog_R1_C3.png";
import frog_R1_C4 from "../assets/images/frog_R1_C4.png";
import frog_R1_C5 from "../assets/images/frog_R1_C5.png";
import frog_R1_C6 from "../assets/images/frog_R1_C6.png";
import frog_R1_C7 from "../assets/images/frog_R1_C7.png";
import frog_R2_C1 from "../assets/images/frog_R2_C1.png";
import frog_R2_C2 from "../assets/images/frog_R2_C2.png";
import frog_R2_C3 from "../assets/images/frog_R2_C3.png";
import frog_R2_C4 from "../assets/images/frog_R2_C4.png";
import frog_R2_C5 from "../assets/images/frog_R2_C5.png";
import frog_R2_C6 from "../assets/images/frog_R2_C6.png";
import frog_R2_C7 from "../assets/images/frog_R2_C7.png";

export const galleryData = [
  frog_R1_C1,
  frog_R1_C2,
  frog_R1_C3,
  frog_R1_C4,
  frog_R1_C5,
  frog_R1_C6,
  frog_R1_C7,
  frog_R2_C1,
  frog_R2_C2,
  frog_R2_C3,
  frog_R2_C4,
  frog_R2_C5,
  frog_R2_C6,
  frog_R2_C7,
];
